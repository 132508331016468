import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';

import {ItemListFilter, ItemListItem, ItemListMapper} from "../../shared/item-list/ItemListItem";
import {
  ItemSaveAction,
  ListEditAction,
  ListViewAction,
  MultiDeleteAction,
  MultiListAction
} from "../../shared/models/Actions";
import {ItemListComponent} from "../../shared/item-list/item-list.component";
import {PagetitleAction} from "../../shared/pagetitle/pagetitle-action";
import {PendingChangesBlocker} from "../../core/guards/pending-changes-view.guard";
import {ApiService} from "../../core/services/api-service/api.service";
import {TranslateService} from "@ngx-translate/core";
import {ListActionEvent} from "../../shared/models/ListActionEvent";
import {MultiListActionEvent} from "../../shared/models/MultiListActionEvent";
import {ItemActionEvent} from "../../shared/models/ItemActionEvent";
import {ITimeModelDynamicForm} from "./time-model-forms/ITimeModelDynamicForm";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {
  TimeModelPointInTimeFormComponent
} from "./time-model-forms/time-model-point-in-time-form/time-model-point-in-time-form.component";
import {
  TimeModelIntervalFormComponent
} from "./time-model-forms/time-model-interval-form/time-model-interval-form.component";
import {
  TimeModelDailyBasisFormComponent
} from "./time-model-forms/time-model-daily-basis-form/time-model-daily-basis-form.component";
import {
  TimeModelRepetitionFormComponent
} from "./time-model-forms/time-model-repetition-form/time-model-repetition-form.component";
import {
  TimeModelListOfDaysFormComponent
} from "./time-model-forms/time-model-list-of-days-form/time-model-list-of-days-form.component";
import {Trigger} from "../../shared/util/Trigger";
import {FormatterService} from "../../core/services/formatter-service/formatter.service";
import {ChangeDetectorForm} from "../../shared/util/change-detector/ChangeDetectorForm";
import {IntervalSelectorComponent} from "./time-model-forms/interval-selector/interval-selector.component";
import {ItemManager} from "../../shared/item-list/ItemManager";
import {SubmitUtils} from "../../shared/util/SubmitUtils";
import {TimeModelDtoWithLicense} from "../../core/interfaces/interfaceExtenders";
import {getGlobalTimeModels, setGlobalTimeModelEditName, setGlobalTimeModels} from "../../shared/util/timeModels";
import {TimeModelUniqueNameValidator} from "../../core/validators/time-model-unique-name-validator.service";
import {TimeModelDto} from "../../shared/entities/TimeModel/TimeModelDto";
import {SessionManager} from "../../core/services/auth-service/support-services/SessionManager";
import {TimeModelIntervalDto} from "../../shared/entities/TimeModel/TimeModelIntervalDto";
import {TIME_MODEL_TYPE} from "../../shared/lookup/timemodel.lookup";
import {LookupMapping} from "../../shared/lookup/lookup";
import {firstValueFrom, take} from "rxjs";
import {ToastService} from "../../shared/notification/toast/toast.service";
import {ModalService} from "../../shared/notification/modal/modal.service";
import {ChangeDetectorValue} from "../../shared/util/change-detector/ChangeDetectorValue";
import {TimeModelBasic} from "../../shared/util/change-detector/models/time-models-basic";

@Component({
  selector: 'app-time-models',
  templateUrl: './time-models.component.html',
  styleUrls: ['./time-models.component.scss'],
  animations: [Trigger.FadeAnimation]
})
export class TimeModelsComponent implements OnInit, ItemListMapper<TimeModelDtoWithLicense>, PendingChangesBlocker {
  _isLoading: boolean = false;
  protected state: 'edit' | 'add' | 'view' | 'overview' = 'overview';

  @ViewChild(ItemListComponent) appItemList!: ItemListComponent<TimeModelDtoWithLicense>;
  @ViewChild('viewContainer', {read: ViewContainerRef}) viewContainerRef!: ViewContainerRef;
  @ViewChild(IntervalSelectorComponent) intervalSelector!: IntervalSelectorComponent;

  itemManager: ItemManager<TimeModelDtoWithLicense>
  selectedItem: TimeModelDtoWithLicense | undefined;
  selectedChangeValue: ChangeDetectorValue = new ChangeDetectorValue({} as TimeModelBasic, () => {this.checkPendingChanges()});
  lookupData: LookupMapping[];
  itemSelected = false

  // Actions
  saveAction = new ItemSaveAction()

  pagetitleActions: PagetitleAction[] = [];
  addAction = new PagetitleAction("BUTTON.ADD", "add-time-model", "btn-outline-primary", "mdi mdi-plus-circle-outline")

  multiSelectActions: MultiListAction[] = []
  multiDeleteAction = new MultiDeleteAction()

  // Search
  typeaheadIndex = new Set<string>()

  get searchEntries() {
    return [...this.typeaheadIndex.values()]
  }

  searchQuery: string = ""
  searchFilter = new ItemListFilter<TimeModelDtoWithLicense>(item => {
    return String(item.shortName).toLowerCase().includes(this.searchQuery.toLowerCase())
  })

  // TimeModel Forms
  selectedTimeModelComponent?: ITimeModelDynamicForm
  showCustomForm = false
  showIntervalSelector = false
  isLicenseBusiness: boolean;
  isLicenseExpired: boolean;

  registeredTimeModelForms: { [index: string]: any } = {
    '1': TimeModelPointInTimeFormComponent,
    '2': TimeModelIntervalFormComponent,
    '3': TimeModelDailyBasisFormComponent,
    '4': TimeModelRepetitionFormComponent,
    '5': TimeModelListOfDaysFormComponent
  }

  validatorMaxLength64: number = 64;
  validatorMaxLength32: number = 32

  constructor(
    private apiService: ApiService,
    private notification: ToastService,
    private modalService: ModalService,
    private translate: TranslateService,
    private formatter: FormatterService
  ) {
    this.lookupData = TIME_MODEL_TYPE;
    this.itemManager = new ItemManager<TimeModelDtoWithLicense>(this, notification)

    const basic: TimeModelBasic = new TimeModelBasic();
    this.selectedChangeValue = new ChangeDetectorValue(basic, () => {this.checkPendingChanges()}, basic.validators());

    const sessionManager: SessionManager = SessionManager.getInstance();
    this.isLicenseExpired = sessionManager.isLicenseExpired;
    this.isLicenseBusiness = sessionManager.isLicenseBusiness;
  }

  async ngOnInit() {
    this._isLoading = true;
    this.apiService.timeModel.getAll().then(observer => observer.pipe(take(1)).subscribe(async timeModels => {
      setGlobalTimeModels(timeModels || []);
      await this.loadItemsAndBuildTypeahead();
      this._isLoading = false;

      if (this.apiService.timeModel.canAdd) {
        this.pagetitleActions = [this.addAction];
      }

      if (this.apiService.timeModel.canDelete) {
        this.multiSelectActions.push(this.multiDeleteAction)
      }
    }));
  }

  async loadItemsAndBuildTypeahead() {
    this.apiService.timeModel.getAll().then(observer => observer.pipe(take(1)).subscribe(timeModels => {
      this.itemManager.setItems((timeModels as TimeModelDtoWithLicense[]).map(t => {
        t.licenseTypeId = this.isLicenseBusiness && !this.isLicenseExpired ? 2 : 1;
        return t;
      }));

      this.typeaheadIndex.clear();
      this.itemManager.forEach(item => {
        this.typeaheadIndex.add(item.name);
      });
    }));
  }

  // Interfaces
  checkPendingChanges() {
    const hasChanged = this.selectedChangeValue.hasChanges || (this.selectedTimeModelComponent?.changed || false) || this.intervalSelectorChanges;

    this.saveAction.disabled = !hasChanged || !this.intervalSelectorValid || (this.selectedChangeValue.hasChanges && !this.selectedChangeValue.isValid);
  }

  hasPendingChanges(): boolean {
    return this.state == 'add' || this.state != 'overview' && (this.selectedChangeValue.hasChanges || (this.selectedTimeModelComponent?.changed || false) || this.intervalSelectorChanges);
  }

  mapToItemList(item: TimeModelDtoWithLicense): ItemListItem<TimeModelDtoWithLicense> {
    const itemListItem = new ItemListItem<TimeModelDtoWithLicense>(item.uuid, item.shortName, item)
      .addInfo(item!.description || '')
      .addAction((this.accessReadonly || item.shortName == '24/7') ? new ListViewAction() : new ListEditAction())
      .setImage(`assets/ces/time-model/${this.apiService.timeModel.getImageNameForTimeModelType(item.modelTypeId)}.svg`)
      .setReadonly((this.accessReadonly || item.shortName == '24/7'));

    if (item.licenseTypeId === 1) {
      itemListItem.setSelectable(false)
    }

    return itemListItem;
  }

  // Events
  protected intervalSelectorValid: boolean = true;
  private intervalSelectorChanges: boolean = false;
  changes() {
    this.intervalSelectorChanges = false;
    (this.intervalSelector?.timeModelInterval || []).forEach((interval) => {
      if (interval.unique < 0 ||
        interval.timeStart.hasChanges || interval.timeEnd.hasChanges) {
        this.intervalSelectorChanges = true;
      }
    });
    this.intervalSelectorValid = !this.intervalSelector?.isInvalid ?? true;
    this.checkPendingChanges();
  }

  onSearch(search: string) {
    this.searchQuery = search
    this.searchFilter.triggerItemUpdate()
  }

  async onMultiDeleteAction(event: MultiListActionEvent<TimeModelDto>) {
    let success = true
    for (const timeModel of event.items) {
      (await this.apiService.timeModel.delete(timeModel.uuid)).subscribe({
        next: () => setGlobalTimeModels((getGlobalTimeModels() || [] as TimeModelDto[]).filter(model => timeModel.uuid != model.uuid)),
        error: err => success = false,
        complete: async () => {
          if (success) {
            this.notification.showSuccess('NOTIFICATION.TOAST.SUCCESS.DELETE');
          }
          await this.loadItemsAndBuildTypeahead();
        }
      });
    }
  }

  async onAddTimeModel() {
    if (SubmitUtils.checkControls(this.notification, true, ...this.checksChanges.abstracts)
      || SubmitUtils.reflectCheck(this.notification, true, ...this.checksChanges.reflects)
      || this.hasPendingChanges()) {
      if (await SubmitUtils.warnPendingChanges(this.modalService)) {
        return;
      }
    }
    setGlobalTimeModelEditName(undefined);

    this.state = 'add';
    const currentTime = this.formatter.getDateSeconds(true);
    const timeModelDto: TimeModelDtoWithLicense = TimeModelDto.emptyTimeModelDto() as TimeModelDtoWithLicense;
    timeModelDto.intervals = Array.of(new TimeModelIntervalDto(
      "",
      -1,
      currentTime,
      this.formatter.addDate(currentTime, 0, 0, 1)
    ));

    this.onSelect(timeModelDto);

    let timeModelDtoLicense: TimeModelDtoWithLicense = timeModelDto as TimeModelDtoWithLicense;
    timeModelDtoLicense.licenseTypeId = this.isLicenseBusiness && !this.isLicenseExpired ? 2 : 1;

    const selectedListItem = this.mapToItemList(timeModelDtoLicense);
    selectedListItem.name = this.translate.instant("TIME_MODELS.ACTION.NEW_TIME_MODEL_NAME")
    this.appItemList.setSelectedListItem(selectedListItem, new ItemSaveAction())
  }

  onResetView() {
    this.selectedItem = undefined;
    this.viewContainerRef.clear()
    this.selectedTimeModelComponent = undefined
    this.checkPendingChanges()
    this.intervalSelectorChanges = false;
    this.intervalSelectorValid = true;
    this.state = 'overview';
  }

  async onSelectEvent(actionEvent: ListActionEvent<TimeModelDtoWithLicense>) {
    setGlobalTimeModelEditName(actionEvent?.item?.shortName);

    this.state = 'view';
    if (actionEvent == undefined) {
      return
    }

    this.onSelect(actionEvent.item)

    if (!this.apiService.timeModel.accessIsReadOnly) {
      actionEvent.addItemAction(this.saveAction)
    }
  }

  private onSelect(item: TimeModelDtoWithLicense) {
    this.selectedItem = item;
    const modelTypeId = `${item.modelTypeId}`
    let basic: TimeModelBasic = new TimeModelBasic();
    basic.values = {
      uuid: item.uuid,
      timeModelNumber: item.timeModelNumber,
      modelTypeId: modelTypeId,
      shortName: item.shortName,
      description: item.description || ''
    }
    this.selectedChangeValue = new ChangeDetectorValue(basic, () => {this.checkPendingChanges()}, basic.validators());
    this.checkPendingChanges()
    this.setSelectedTemplate(modelTypeId)
  }

  onUpdateModel(event: Event) {
    const value = (event.target as HTMLSelectElement).value
    this.setSelectedTemplate(value)
  }

  private setSelectedTemplate(modelTypeId: string) {
    this.viewContainerRef.clear()

    const view = this.viewContainerRef.createComponent<ITimeModelDynamicForm>(this.registeredTimeModelForms[modelTypeId])
    view.instance.initialize(this.selectedItem! as TimeModelDto, this.apiService.timeModel.accessIsReadOnly);

    this.showCustomForm = view.instance.customFormEnabled
    this.showIntervalSelector = view.instance.intervalEnabled
    this.selectedTimeModelComponent = view.instance
    view.instance.onChanges = () => {
      this.checkPendingChanges()
    }
  }

  async onEditEvent(actionEvent: ItemActionEvent<TimeModelDtoWithLicense>) {
    setGlobalTimeModelEditName(actionEvent?.item?.shortName);
    if (actionEvent == undefined) return;

    if (actionEvent.action instanceof ItemSaveAction) {
      await this.onSubmit(actionEvent.returnToList)
    }
  }

  get checksChanges(): {abstracts: AbstractControl[], reflects: boolean[]} {
    let formsToCheck: AbstractControl[] = [];
    let reflectCheck: boolean[] = [this.intervalSelectorValid, this.selectedChangeValue.hasChanges ? this.selectedChangeValue.isValid : true];

    if (this.selectedTimeModelComponent?.dynamicForm != null) {
      if (typeof this.selectedTimeModelComponent?.dynamicForm === 'boolean') {
        reflectCheck.push(this.selectedTimeModelComponent.dynamicForm);
      } else {
        formsToCheck.push(this.selectedTimeModelComponent.dynamicForm);
      }
    }
    return {
      abstracts: formsToCheck,
      reflects: reflectCheck
    };
  }

  async onSubmit(returnToList: () => void) {
    if (SubmitUtils.checkControls(this.notification, true, ...this.checksChanges.abstracts)
      || SubmitUtils.reflectCheck(this.notification, true, ...this.checksChanges.reflects)) {
      return;
    }

    let timeModel = this.selectedTimeModelComponent?.getTimeModel() as TimeModelDtoWithLicense | undefined;
    if (this.selectedTimeModelComponent == null || timeModel == null) return;

    // parse intervals if interval is enabled
    if (this.selectedTimeModelComponent?.intervalEnabled) {
      timeModel.intervals = [...this.intervalSelector.timeModelInterval.map(value =>
         new TimeModelIntervalDto(
          value.timeModelUuid,
          value.id,
          this.formatter.formatFromNgbTimePicker(value.timeStart.value),
          this.formatter.formatFromNgbTimePicker(value.timeEnd.value)
         )
      )];
    }

    // parse basic form
    timeModel.uuid = this.selectedChangeValue.value.uuid;
    timeModel.timeModelNumber = this.selectedChangeValue.value.timeModelNumber;
    timeModel.shortName = this.selectedChangeValue.value.shortName;
    timeModel.description = this.selectedChangeValue.value.description
    timeModel.modelTypeId = parseInt(this.selectedChangeValue.value.modelTypeId) || -1;

    // add or update time model
    if (this.state == 'add') {
      timeModel = await firstValueFrom(await this.apiService.timeModel.add(this.typeConversionsForKMM(timeModel))) as TimeModelDtoWithLicense;
      let globalTimeModels: TimeModelDto[] = (getGlobalTimeModels() || [] as TimeModelDto[]);
      globalTimeModels.push(timeModel as TimeModelDto);
      setGlobalTimeModels(globalTimeModels);
    } else {
      timeModel = await firstValueFrom(await this.apiService.timeModel.update(this.typeConversionsForKMM(timeModel))) as TimeModelDtoWithLicense;
    }

    if (timeModel == null) {
      return
    }

    // notification and list update
    this.itemManager.updateItem(timeModel, true);
    returnToList()
    this.onResetView()
    setGlobalTimeModelEditName(undefined);
  }

  async onDelete(returnToList: () => void) {
    if (await this.apiService.timeModel.delete(this.selectedItem!.uuid)) {
      setGlobalTimeModels((getGlobalTimeModels() || [] as TimeModelDto[]).filter(model => this.selectedItem!.uuid != model.uuid));
      this.itemManager.removeItemsById(this.selectedItem!.uuid);
      returnToList();
    }
  }

  private typeConversionsForKMM(timeModel: TimeModelDtoWithLicense): TimeModelDto {

    timeModel.repetitionTypeId = timeModel.repetitionTypeId === null ? undefined : timeModel.repetitionTypeId;
    timeModel.repetitionValue = timeModel.repetitionValue === null ? undefined : timeModel.repetitionValue;

    timeModel.executionRepetition = (timeModel.executionRepetition || []);
    timeModel.intervals = (timeModel.intervals || []);

    return timeModel as TimeModelDto;
  }

  get timeModelTypeForTranslation(): string {
    return `TIME_MODELS.TYPE.${[...TIME_MODEL_TYPE.filter(value => this.selectedChangeValue.value.modelTypeId == `${value.id}`), {
      id: -1,
      value: 'LOGBOOK.DETAILS.DATA_UNKNOWN'
    }][0].value.toUpperCase()}`;
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  get accessReadonly(): boolean {
    return this.apiService.timeModel.accessIsReadOnly || (this.selectedItem?.shortName || '') === '24/7';
  }
}

