@if(params.value != undefined) {
  <form>
    <div class="row row-cols-lg-6 g-4">

      <div *ngIf="paramsManagedByGroup && paramsGroup != null" id="deviceParamsGroupWarning" class="col-lg-12">
        <div class="card shadow-none ces-bg-secondary mb-0 p-2">
        <span class="text-center">
          {{ "LOCKING_DEVICES.DEVICES.PARAMS_READONLY.PREFIX" | translate }} "{{ paramsGroup.name }}
          "          {{ "LOCKING_DEVICES.DEVICES.PARAMS_READONLY.SUFFIX" | translate }}
        </span>
        </div>
      </div>

      <div [classList]="'col-lg-6'">
        <div class="card h-100 shadow-none">
          <h6 class="card-header">{{ "LOCKING_DEVICES.DEVICES.SECTION.SETTINGS" | translate }}</h6>
          <div class="card-body pb-0">

            <div [ngClass]="{'form-floating form-floating-custom mb-3': true}">
              <input [readonly]="isReadonly" type="number" class="form-control"
                     id="openingDuration" name="openingDuration"
                     [(ngModel)]="params.value.openingDuration"
                     [ngClass]="{ 'invalid-feedback-ngd': !params.isSpecificGroupValid('openingDuration'),
                    'pending-changes': params.hasSpecificChanges('openingDuration') }"
                     min="3" max="180">
              <label
                for="openingDuration">{{ "LOCKING_DEVICES.DEVICES.FIELD.OPENING_DURATION.TEXT" | translate }}</label>
              <div class="form-floating-icon">
                <i icon="interval"></i>
              </div>
              <div *ngIf="!params.isSpecificGroupValid('openingDuration')" class="invalid-feedback">
                @if (!params.isSpecificValid('openingDurationRequired')) {
                  <div> {{ "LOCKING_DEVICES.DEVICES.FIELD.OPENING_DURATION.REQUIRED" | translate }}</div>
                } @else if (!params.isSpecificValid('openingDurationMin')) {
                  <div>{{ "LOCKING_DEVICES.DEVICES.FIELD.OPENING_DURATION.MIN" | translate }}</div>
                } @else if (!params.isSpecificValid('openingDurationMax')) {
                  <div>{{ "LOCKING_DEVICES.DEVICES.FIELD.OPENING_DURATION.MAX" | translate }}</div>
                }
              </div>
            </div>

            <div class="form-floating form-floating-custom mb-3 switch-new-design-black">
              <div id="formBeeper" class="form-control"
                   [ngClass]="{'pending-changes': params.hasSpecificChanges('beeper')}">
                <div class="form-check form-switch form-switch-md"
                     [ngClass]="{'pointer-events-none': isReadonly}">
                  <input [switchDisabled]="isReadonly" type="checkbox" class="form-check-input" id="beeper"
                         name="beeper" [(ngModel)]="params.value.beeper">
                </div>
              </div>
              <label for="beeper">{{ "LOCKING_DEVICES.DEVICES.FIELD.BEEPER.TEXT" | translate }}</label>
              <div class="form-floating-icon">
                <i icon="audio"></i>
              </div>
            </div>

            <div *ngIf="isLicenseBusiness" class="form-floating form-floating-custom mb-3 switch-new-design-black">
              <div id="formOfficeFunction" class="form-control"
                   [ngClass]="{'pending-changes': params.hasSpecificChanges('officeFunction') }">
                <div class="form-check form-switch form-switch-md" [ngClass]="{'pointer-events-none': isReadonly}">
                  <input [switchDisabled]="isReadonly" type="checkbox" class="form-check-input" id="officeFunction"
                         name="officeFunction" [(ngModel)]="params.value.officeFunction">
                </div>
              </div>
              <label for="officeFunction">{{ "LOCKING_DEVICES.DEVICES.FIELD.OFFICE_FUNCTION.TEXT" | translate }}</label>
              <div class="form-floating-icon">
                <i icon="office"></i>
              </div>
            </div>

            <div *ngIf="isLicenseBusiness" class="form-floating form-floating-custom mb-3">
              <date-time-picker
                [readonly]="isReadonly || !isLicenseBusiness || params.value.officeFunction != true"
                style="flex-grow: 1"
                titleText="LOCKING_DEVICES.DEVICES.FIELD.OFFICE_TIMEOUT.TEXT"
                [(timeValue)]="params.value.officeTimeout"
                [invalidTime]="isLicenseBusiness && !params.isSpecificGroupValid('officeTimeout')"
                [showChanges]="params.hasSpecificChanges('officeTimeout') && params.value.officeFunction == true">
              </date-time-picker>
            </div>
            <div *ngIf="isLicenseBusiness && !params.isSpecificGroupValid('officeTimeout')"
                 class="invalid-feedback error-message d-block">
              <div style="position: absolute">{{ "LOCKING_DEVICES.DEVICES.FIELD.OFFICE_TIMEOUT.MIN" | translate }}</div>
            </div>

            <div *ngIf="isOnlineAvailable" class="form-floating form-floating-custom">
              <select *ngIf="!isReadonly; else variant" class="form-select" id="variant">
                <option [ngValue]="params.value.variantId"
                        [ngClass]="{'pending-changes': params.hasSpecificChanges('variantId'),
                          'invalid-feedback-ngd': !params.isSpecificGroupValid('variantId')}"
                        *ngFor="let variant of VARIANT"
                        [value]="variant.id">{{ ("LOCKING_DEVICES.DEVICES.VARIANT." + variant.value.toUpperCase()) | translate }}
                </option>
              </select>
              <ng-template #variant>
                <input readonly id="variant" class="form-control" [value]="variantForTranslation | translate">
              </ng-template>
              <label for="variant">{{ "LOCKING_DEVICES.DEVICES.FIELD.VARIANT.TEXT" | translate }}</label>
              <div class="form-floating-icon">
                <i icon="online"></i>
              </div>
            </div>

            <div *ngIf="showLogging" class="form-floating form-floating-custom mt-3">
              <input readonly type="text" class="form-control" id="logging"
                     [value]="(parseInt(params.value.loggingId) == 2 ? 'LOCKING_DEVICES.DEVICES.LOGGING.ON' : 'LOCKING_DEVICES.DEVICES.LOGGING.OFF') | translate">
              <label for="logging">{{ "LOCKING_DEVICES.DEVICES.FIELD.LOGGING.TEXT" | translate }}</label>
              <div class="form-floating-icon">
                <i icon="log"></i>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div *ngIf="isLicenseBusiness" class="col-lg-6">
        <div class="card h-100 shadow-none">
          <h6 class="card-header">{{ "LOCKING_DEVICES.DEVICES.SECTION.TIME_MODELS" | translate }}</h6>
          <div class="card-body">

            <div class="form-floating form-floating-custom mb-3">
              <select *ngIf="!isReadonly; else openTimeModelRef" [(ngModel)]="params.value.openTimeModelUuid"
                      [ngClass]="{'form-select': true,
                        'invalid-feedback-ngd': !params.isSpecificGroupValid('openTimeModelUuid'),
                        'pending-changes': params.isSpecificGroupValid('openTimeModelUuid') && params.hasSpecificChanges('openTimeModelUuid')}"
                      id="openTimeModel" name="openTimeModelUuid">
                <option *ngFor="let timeModel of timeModelsData" [ngValue]="timeModel.uuid">{{ timeModel.shortName }}
                </option>
              </select>
              <ng-template #openTimeModelRef>
                <input readonly id="openTimeModel" class="form-control"
                       [value]="timeModelName(_selectedItem?.openTimeModelUuid)">
              </ng-template>
              <label for="openTimeModel">{{ "LOCKING_DEVICES.DEVICES.FIELD.TIME_MODEL.OPEN" | translate }}</label>
              <div class="form-floating-icon">
                <i icon="check"></i>
              </div>
            </div>

            <div class="form-floating form-floating-custom mb-3">
              <select *ngIf="!isReadonly; else closeTimeModelRef" [(ngModel)]="params.value.closeTimeModelUuid"
                      [ngClass]="{'form-select': true,
                        'invalid-feedback-ngd': !params.isSpecificGroupValid('closeTimeModelUuid'),
                        'pending-changes': params.isSpecificGroupValid('closeTimeModelUuid') && params.hasSpecificChanges('closeTimeModelUuid')}"
                      id="closeTimeModel" name="closeTimeModel">
                <option *ngFor="let timeModel of timeModelsData"
                        [ngValue]="timeModel.uuid">{{ timeModel.shortName }}
                </option>
              </select>
              <ng-template #closeTimeModelRef>
                <input readonly id="closeTimeModel" class="form-control"
                       [value]="timeModelName(_selectedItem?.closeTimeModelUuid)">
              </ng-template>
              <label for="closeTimeModel">{{ "LOCKING_DEVICES.DEVICES.FIELD.TIME_MODEL.CLOSE" | translate }}</label>
              <div class="form-floating-icon">
                <i icon="cross"></i>
              </div>
            </div>

            <div *ngIf="!params.isSpecificGroupValid('openTimeModel') || !params.isSpecificGroupValid('closeTimeModel')"
                 class="d-block invalid-feedback">
              <div>{{ "LOCKING_DEVICES.DEVICES.FIELD.TIME_MODEL.EQUAL" | translate }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
}
