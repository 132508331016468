import {User} from "./User";

export class UserRequestDto extends User {
   accessProfiles: string[] | undefined;
   media: string[] | undefined;

   constructor(uuid: string, username: string, mail: string, roleId: number, blocked: boolean, changePassword: boolean) {
     super(uuid, username, mail, roleId, blocked, changePassword);
   }

   static emptyUserRequestDto(): UserRequestDto {
     return new UserRequestDto('', '', '', 0, true, true);
   }
}
