import {Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {NodeManager} from "./support-services/NodeManager";
import {firstValueFrom, Subject} from "rxjs";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {RegisterUserDto} from "../../../shared/entities/user/RegisterUserDto";
import {UserLoginResponseDto} from "../../../shared/entities/user/UserLoginResponseDto";
import {CESService} from "../ces.service";
import {sha256} from "js-sha256";
import {UserSessionInfoDto} from "../../../shared/entities/user/UserSessionInfoDto";
import {jwtDecode} from "jwt-decode";
import {UserResetPasswordDto} from "../../../shared/entities/user/UserResetPasswordDto";
import {LocalStorageSchemaService} from "../json-schema-service/local-storage-schema.service";
import {ToastService} from "../../../shared/notification/toast/toast.service";
import {ClientDto} from "../../../shared/entities/client/ClientDto";

@Injectable({providedIn: 'root'})

/**
 * Auth-service Component
 */
export class AuthenticationService extends CESService {

  $token: Subject<string> = new Subject<string>();
  $loginOrLogout: Subject<string> = new Subject<string>();

  constructor(
    router: Router,
    httpClient: HttpClient,
    notification: ToastService) {
    super(router, httpClient, notification);
  }

  /**
   * Performs the register
   * @param system system-id
   * @param username username of user
   * @param firstname firstname of user
   * @param lastname lastname of user
   * @param email email of user
   */
  async register(system: string, username: string, firstname: string, lastname: string, email: string) {
    const newUser: RegisterUserDto = new RegisterUserDto(system, new LocalStorageSchemaService().localStorage('lang').plain, username, firstname, lastname, email);
    const result = await firstValueFrom(this.httpClient.post<UserLoginResponseDto>(await this.buildUrl(system, '/users/registration'), newUser, {observe: 'response'}));
    if (result.ok) {
      await this.router.navigate(["/user/register/thankyou"]);
    }
  }

  /**
   * Performs the auth
   * @param system system-id
   * @param username username of user
   * @param password password of user
   */
  async login(system: string, username: string, password: string): Promise<UserSessionInfoDto | undefined> {
    const response = await firstValueFrom(this.httpClient.post<UserLoginResponseDto>(await this.buildUrl(system, '/auth/login'),
      {
        'systemId': system,
        'userPassword': sha256(password),
        'username': username
      }));
    if (response == null) {
      return Promise.resolve(undefined);
    }
    this.$token.next(response.token);
    this.$loginOrLogout.next('login');
    return new UserSessionInfoDto(
      response.token,
      response.expireAt,
      response.changePassword,
      (response.userUuid || (jwtDecode(response.token) as { exp: number, system_uuid: string, user_uuid: string }).user_uuid) || '',
      response.roleId,
      'webclient',
      username,
      false,
      0,
      false,
      'login'
    );
  }

  /**
   * Performs change the current password
   * @param password old password of user
   * @param newPassword new password
   * @param confirmPassword confirm of new password
   */
  async changePassword(password: string, newPassword: string, confirmPassword: string): Promise<boolean> {
    if (!this.sessionManager.isValidSession()) {
      await this.router.navigate(["/user/login"])
      return false
    }
    const node = NodeManager.getInstance().dataNodeConfiguration;
    if (newPassword != confirmPassword || node == undefined) {
      return false;
    }
    return (await firstValueFrom(this.httpClient.patch<any>(await this.buildUrl(undefined, '/users/changePassword'),
      {newPassword: sha256(newPassword), currentPassword: sha256(password)},
      {observe: 'response'})))!.ok;
  }

  /**
   * Logout the user
   */
  async logout(): Promise<boolean> {
    const logout: boolean = (await firstValueFrom(this.httpClient.delete<any>(await this.buildUrl(undefined, '/auth/logout'), {observe: 'response'}))).ok;
    this.sessionManager.clearCredentials()
    this.nodeManager.onMasternodeReset();
    this.$token.next('');
    this.$loginOrLogout.next('logout');
    return logout;
  }

  /**
   * Reset password
   * @param system system name
   * @param user user name
   */
  async resetPassword(system: string, user: string) {
    const userResetPasswordDto: UserResetPasswordDto = new UserResetPasswordDto(system, user);
    const url: string = await this.buildUrl(system, '/users/passwordReset');
    if (url == '') {
      return false;
    }
    return (await firstValueFrom(this.httpClient.post<any>(url, userResetPasswordDto, {observe: 'response'}))).ok;
  }

  /**
   * Reset password
   * @param force forces the BE to grant a new token
   */
  async refreshToken(force?: boolean): Promise<HttpResponse<any>> {
    return await firstValueFrom(this.httpClient.get<any>(
      await this.buildUrl(undefined, `/auth/refreshToken${force ? '?force' : ''}`), {observe: 'response'}));
  }

  isValidSession(): boolean {
    return this.sessionManager.isValidSession();
  }

  async relogin(password: string): Promise<boolean> {
    const system: ClientDto = this.sessionManager.getSystemData()!;
    const session: UserSessionInfoDto = this.sessionManager.sessionCredentials!;
    const result = await this.login(system.systemId, session.username, password);
    if (result == null) {
      return false;
    }
    this.sessionManager.setSessionCredentials(new UserSessionInfoDto(
      result!.token,
      Math.floor(Date.now() / 1000) + 300,
      result!.changePassword,
      session.userUUID,
      session.roleId,
      session.clientName,
      session.username,
      session.hasProfileImage,
      session.systemIdentifier,
      session.onlineAvailable
    ));
    this.$token.next(result.token || '');
    this.$loginOrLogout.next('login');
    return true;
  }
}

