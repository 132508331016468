import {UserRequestDto} from "../../../entities/user/UserRequestDto";
import {ChangeDetectorModel} from "../ChangeDetectorValue";
import {EventEmitter} from "@angular/core";
import {FormatterService} from "../../../../core/services/formatter-service/formatter.service";
import {EmailStatus} from "../../../../core/enums/emailStatus.enum";

export class UserWithImage implements UserRequestDto, ChangeDetectorModel {
  formatter: FormatterService = new FormatterService();
  valueChange: EventEmitter<any>;

  private readonly _validatorMaxLength64: number = 64;
  private readonly _validatorMaxLength32: number = 32;
  private readonly _validatorMaxLength10: number = 10;
  private readonly _validatorPatternPhone: RegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\.\/0-9]*$/;
  private readonly _validatorPatternEMail: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  private _userImageData: any;
  private _userImage: any;
  private _accessProfiles: string[] | undefined;
  private _activity: string | undefined;
  private _area: string | undefined;
  private _blocked: boolean = false;
  private _changePassword: boolean = false;
  private _city: string | undefined;
  private _costCentre: string | undefined;
  private _countryId: number | undefined;
  private _dateOfBirth: number | undefined;
  private _department: string | undefined;
  private _firstName: string | undefined;
  private _imageAvailable: boolean | undefined;
  private _imageHash: string | undefined;
  private _lastName: string | undefined;
  private _mail: string = '';
  private _media: string[] | undefined;
  private _originClient: string | undefined;
  private _personnelNo: string | undefined;
  private _phoneMobile: string | undefined;
  private _phoneOffice: string | undefined;
  private _phonePrivate: string | undefined;
  private _roleId: number = 4;
  private _streetHouseNumber: string | undefined;
  private _username: string = '';
  private _uuid: string = '';
  private _zipCode: string | undefined;
  private _emailStatus: EmailStatus | undefined;

  constructor() {
    this.valueChange = new EventEmitter();
  }

  validators(): Map<string, (input: any) => boolean> {
    return new Map<string, (input: any) => boolean>()
      .set('activityMax', (input: any) => {
        return (input._activity || '').length <= this._validatorMaxLength64;
      }).set('areaMax', (input: any) => {
        return (input._area || '').length <= this._validatorMaxLength64;
      }).set('departmentMax', (input: any) => {
        return (input._department || '').length <= this._validatorMaxLength64;
      }).set('cityMax', (input: any) => {
        return (input._city || '').length <= this._validatorMaxLength64;
      }).set('firstNameRequired', (input: any) => {
        return input._firstName != undefined && (input._firstName || '').length > 0;
      }).set('firstNameMax', (input: any) => {
        return (input._firstName || '').length <= this._validatorMaxLength64;
      }).set('lastNameRequired', (input: any) => {
        return input._lastName != undefined && (input._lastName || '').length > 0;
      }).set('lastNameMax', (input: any) => {
        return (input._lastName || '').length <= this._validatorMaxLength64;
      }).set('usernameRequired', (input: any) => {
        if ((input._mail || '').length > 0) {
          return (input._username || '').length > 0 && (input._mail || '').length > 0;
        }
        return true;
      }).set('usernameMax', (input: any) => {
        return (input._username || '').length <= this._validatorMaxLength32;
      }).set('personnelNoMax', (input: any) => {
        return (input._personnelNo || '').length <= this._validatorMaxLength64;
      }).set('phoneMobileMax', (input: any) => {
        return (input._phoneMobile || '').length <= this._validatorMaxLength64;
      }).set('phoneOfficeMax', (input: any) => {
        return (input._phoneOffice || '').length <= this._validatorMaxLength64;
      }).set('phonePrivateMax', (input: any) => {
        return (input._phonePrivate || '').length <= this._validatorMaxLength64;
      }).set('phoneMobilePattern', (input: any) => {
        return (input._phoneMobile || '').length > 0 ? this._validatorPatternPhone.test(input._phoneMobile || '') : true;
      }).set('phoneOfficePattern', (input: any) => {
        return (input._phoneOffice || '').length > 0 ? this._validatorPatternPhone.test(input._phoneOffice || '') : true;
      }).set('phonePrivatePattern', (input: any) => {
        return (input._phonePrivate || '').length > 0 ? this._validatorPatternPhone.test(input._phonePrivate || '') : true;
      }).set('streetHouseNumberMax', (input: any) => {
        return (input._streetHouseNumber || '').length <= this._validatorMaxLength64;
      }).set('mailMax', (input: any) => {
        return (input._mail || '').length <= this._validatorMaxLength64;
      }).set('mailPattern', (input: any) => {
        if ((input._mail || '').length > 0) {
          return this._validatorPatternEMail.test(input._mail || '');
        }
        return true;
      }).set('mailRequired', (input: any) => {
        if ((input._username || '').length > 0) {
          return (input._username || '').length > 0 && (input._mail || '').length > 0;
        }
        return true;
      }).set('zipCodeMax', (input: any) => {
        return (input._zipCode || '').length <= this._validatorMaxLength10;
      });
  }

  set values(values: any) {
    if (values && typeof values == 'object') {
      for (let key in values) {
        const newVal = this.hasProp(values, key) ? values[key] : this.hasProp(this, `_${key}`) ? values[`_${key}`] : undefined;
        //@ts-ignore
        this.hasProp(this, key) ? this[key] = newVal : this.hasProp(this, `_${key}`) ? this[`_${key}`] = newVal : () => { };
      }
    }
  }

  private hasProp(thisArg: any, args_0: string): boolean {
    return Object.prototype.hasOwnProperty.call(thisArg, args_0);
  }

  private objectToStringArray(o: any[] | undefined): any[] {
    if (o == undefined || o.length == 0) {
      return [];
    }
    const tester: any = o[0];
    if (typeof tester === 'string') {
      return o as string[];
    }
    if (tester.uuid != undefined) {
      return o.map(value => value.uuid);
    }
    return [];
  }

  get toUserRequestDto(): UserRequestDto {
    let userRequestDto: UserRequestDto = new UserRequestDto(
      this.uuid,
      this.username,
      this.mail,
      this.roleId,
      this.blocked,
      this.changePassword
    );
    userRequestDto.accessProfiles = this.accessProfiles;
    userRequestDto.media = this.media;
    userRequestDto.area = this.area;
    userRequestDto.activity = this.activity;
    userRequestDto.city = this.city;
    userRequestDto.costCentre = this.costCentre;
    userRequestDto.countryId = this.countryId;
    userRequestDto.dateOfBirth = this.dateOfBirth;
    userRequestDto.department = this.department;
    userRequestDto.firstName = this.firstName;
    userRequestDto.lastName = this.lastName;
    userRequestDto.mail = this.mail;
    userRequestDto.originClient = this.originClient;
    userRequestDto.personnelNo = this.personnelNo;
    userRequestDto.phoneMobile = this.phoneMobile;
    userRequestDto.phonePrivate = this.phonePrivate;
    userRequestDto.phoneOffice = this.phoneOffice;
    userRequestDto.streetHouseNumber = this.streetHouseNumber;
    userRequestDto.zipCode = this.zipCode;
    return userRequestDto;
  }

  /*
   * Getter & Setter
   */

  get userImageData(): any {
    return this._userImageData;
  }

  set userImageData(value: any) {
    this._userImageData = value;
  }

  get userImage(): any {
    return this._userImage;
  }

  set userImage(value: any) {
    this._userImage = value;
  }

  get accessProfiles(): string[] | undefined {
    return this._accessProfiles;
  }

  set accessProfiles(value: string[] | object[] | undefined) {
    this._accessProfiles = this.objectToStringArray(value);
    this.valueChange.emit(true);
  }

  get activity(): string | undefined {
    return this._activity;
  }

  set activity(value: string | undefined) {
    this._activity = value;
    this.valueChange.emit(true);
  }

  get area(): string | undefined {
    return this._area;
  }

  set area(value: string | undefined) {
    this._area = value;
    this.valueChange.emit(true);
  }

  get blocked(): boolean {
    return this._blocked;
  }

  set blocked(value: boolean) {
    this._blocked = value;
    this.valueChange.emit(true);
  }

  get changePassword(): boolean {
    return this._changePassword;
  }

  set changePassword(value: boolean) {
    this._changePassword = value;
    this.valueChange.emit(true);
  }

  get city(): string | undefined {
    return this._city;
  }

  set city(value: string | undefined) {
    this._city = value;
    this.valueChange.emit(true);
  }

  get costCentre(): string | undefined {
    return this._costCentre;
  }

  set costCentre(value: string | undefined) {
    this._costCentre = value;
    this.valueChange.emit(true);
  }

  get countryId(): number | undefined {
    return this._countryId;
  }

  set countryId(value: number | undefined) {
    this._countryId = value;
    this.valueChange.emit(true);
  }

  get dateOfBirth(): number | undefined {
    return this._dateOfBirth;
  }

  set dateOfBirth(value: number | undefined | { year: number, month: number, day: number }) {
    let val: number | undefined = undefined;
    switch (typeof value) {
      case 'number':
        val = value;
        break;
      case "object":
        if (value != null) {
          val = this.formatter.formatAsUTCFromNgbDatePicker(value).getTime() / 1000;
        }
        break;
      case "undefined":
        break;
      default:
        break;
    }
    this._dateOfBirth = val;
    this.valueChange.emit(true);
  }

  get department(): string | undefined {
    return this._department;
  }

  set department(value: string | undefined) {
    this._department = value;
    this.valueChange.emit(true);
  }

  get firstName(): string | undefined {
    return this._firstName;
  }

  set firstName(value: string | undefined) {
    this._firstName = value;
    this.valueChange.emit(true);
  }

  get imageAvailable(): boolean | undefined {
    return this._imageAvailable;
  }

  set imageAvailable(value: boolean | undefined) {
    this._imageAvailable = value;
    this.valueChange.emit(true);
  }

  get imageHash(): string | undefined {
    return this._imageHash;
  }

  set imageHash(value: string | undefined) {
    this._imageHash = value;
    this.valueChange.emit(true);
  }

  get lastName(): string | undefined {
    return this._lastName;
  }

  set lastName(value: string | undefined) {
    this._lastName = value;
    this.valueChange.emit(true);
  }

  get mail(): string {
    return this._mail;
  }

  set mail(value: string) {
    this._mail = value;
    this.valueChange.emit(true);
  }

  get media(): string[] | undefined {
    return this._media;
  }

  set media(value: string[] | object[] | undefined) {
    this._media = this.objectToStringArray(value);
    this.valueChange.emit(true);
  }

  get originClient(): string | undefined {
    return this._originClient;
  }

  set originClient(value: string | undefined) {
    this._originClient = value;
    this.valueChange.emit(true);
  }

  get personnelNo(): string | undefined {
    return this._personnelNo;
  }

  set personnelNo(value: string | undefined) {
    this._personnelNo = value;
    this.valueChange.emit(true);
  }

  get phoneMobile(): string | undefined {
    return this._phoneMobile;
  }

  set phoneMobile(value: string | undefined) {
    this._phoneMobile = value;
    this.valueChange.emit(true);
  }

  get phoneOffice(): string | undefined {
    return this._phoneOffice;
  }

  set phoneOffice(value: string | undefined) {
    this._phoneOffice = value;
    this.valueChange.emit(true);
  }

  get phonePrivate(): string | undefined {
    return this._phonePrivate;
  }

  set phonePrivate(value: string | undefined) {
    this._phonePrivate = value;
    this.valueChange.emit(true);
  }

  get roleId(): number {
    return this._roleId;
  }

  set roleId(value: number) {
    this._roleId = value;
    this.valueChange.emit(true);
  }

  get streetHouseNumber(): string | undefined {
    return this._streetHouseNumber;
  }

  set streetHouseNumber(value: string | undefined) {
    this._streetHouseNumber = value;
    this.valueChange.emit(true);
  }

  get username(): string {
    return this._username;
  }

  set username(value: string) {
    this._username = value;
    this.valueChange.emit(true);
  }

  get uuid(): string {
    return this._uuid;
  }

  set uuid(value: string) {
    this._uuid = value;
    this.valueChange.emit(true);
  }

  get zipCode(): string | undefined {
    return this._zipCode;
  }

  set zipCode(value: string | undefined) {
    this._zipCode = value;
    this.valueChange.emit(true);
  }

  // Only getter
  get emailStatus(): EmailStatus | undefined {
    return this._emailStatus;
  }

  set emailStatus(value: EmailStatus | undefined) {
    this._emailStatus = value;
    // No Event Emitter
  }
}
