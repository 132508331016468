// this file is automatically generated by git.version.ts script
export const versions = {
      beta: undefined,
      branch: 'feature/user-without-mail',
      buildTime: '2024-11-14T13:13:45.200Z',
      package: '@ces/cesentry-web-client',
      revision: '1ac504d3',
      tag: 'v1.0.3',
      version: '1.2.0',
     };
